
* {
  margin: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  pointer-events: none;
}

.App {
  font-family: 'Baloo 2', cursive;
  display: flex;
  justify-content: center;
}
